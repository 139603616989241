import { useAppParametersStore } from "@multicines/stores";
import { Button } from "artisn-ui-react";
import FacebookSVG from "images/facebook.svg";
import InstagramSVG from "images/instagram.svg";
import LogoSVG from "images/logo-responsive.svg";
import TiktokSVG from "images/tiktok.svg";
import YoutubeSVG from "images/youtube.svg";
import Link from "next/link";
import React from "react";

import Styles from "./Footer.styles";
import { FooterProps as Props } from "./Footer.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";

const { WITH_MULTICINES_PLAY } = CONSTANTS.FEATURE_FLAGS;
const { COMPANY_URL } = CONSTANTS.GENERAL;
const { playURL, fbURL, igURL, ytURL, tkURL } = CONSTANTS.EXTERNALS_URLS;
const { workUsUrl, billingUrl } = CONSTANTS.EXTERNALS_URLS;

const Footer: React.FC<Props> = props => {
  const { className } = props;

  const appParameters = useAppParametersStore(state => state.appParameters);
  const { legalTerms } = appParameters ?? {};
  const { secondIssueUrl, extendFirstUrl, firstIssueUrl, termsAndConditions } =
    legalTerms ?? {};
  const { isTalkShop } = useTalkShop();
  const t = useI18n();

  const clickHandler = () => {
    window.open(playURL, "__blank");
  };

  if (isTalkShop) return null;

  return (
    <Styles className={`Footer ${className}`}>
      <div className="Footer__content">
        <div className="Footer__name-and-social">
          <div className="Footer__logo-and-button">
            <LogoSVG />
            {WITH_MULTICINES_PLAY ? (
              <Button className="Footer__button" onClick={clickHandler}>
                <span>Multicines Play</span>
              </Button>
            ) : null}
          </div>
          <div className="Footer__name-and-social__social">
            <a
              className="Footer__name-and-social__social__icon"
              href={fbURL}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookSVG />
            </a>
            <a
              className="Footer__name-and-social__social__icon"
              href={igURL}
              target="_blank"
              rel="noreferrer"
            >
              <InstagramSVG />
            </a>
            <a
              className="Footer__name-and-social__social__icon"
              href={ytURL}
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeSVG viewBox="0 0 27 25" />
            </a>
            <a
              className="Footer__name-and-social__social__icon"
              href={tkURL}
              target="_blank"
              rel="noreferrer"
            >
              <TiktokSVG />
            </a>
          </div>
        </div>
        <div className="Footer__information">
          <div className="Footer__information__column">
            <div className="Footer__information__title">
              {t.footer.navigation.aboutUs}
            </div>
            {/* TODO: add new multicines review brand
            <Link href="/categories" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.us}
              </div>
            </Link> */}
            <div className="Footer__information__item">
              <a href={workUsUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.workUs}
              </a>
            </div>
            <Link href="/profile/support" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.contact}
              </div>
            </Link>
            {/* TODO: uncomment when FAQ is ready */}
            {/* <div className="Footer__information__item">
              <a href={faqsUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.faq}
              </a>
            </div> */}
            <div className="Footer__information__item">
              <a href={billingUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.billing}
              </a>
            </div>
            <div className="Footer__information__item">
              <a href={termsAndConditions} target="_blank" rel="noreferrer">
                {t.footer.navigation.terms}
              </a>
            </div>
          </div>
          {/* TODO: add columns till 1st july
          <div className="Footer__information__column">
            <div className="Footer__information__title">
              {t.footer.navigation.service}
            </div>
            <Link href="/categories" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.vip}
              </div>
            </Link>
            <Link href="/profile" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.multidrive}
              </div>
            </Link>
            <Link href="/profile/settings" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.snacks}
              </div>
            </Link>
          </div> */}
          {/* TODO: add columns till 1st july
           <div className="Footer__information__column">
            <div className="Footer__information__title">
              {t.footer.navigation.services}
            </div>
            <Link href="/categories" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.ads}
              </div>
            </Link>
            <Link href="/profile" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.coupons}
              </div>
            </Link>
            <Link href="/profile/settings" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.events}
              </div>
            </Link>
            <Link href="/profile/settings" passHref>
              <div className="Footer__information__item">
                {t.footer.navigation.birthday}
              </div>
            </Link>
          </div> */}
          <div className="Footer__information__column">
            <div className="Footer__information__title">
              {t.footer.navigation.legals}
            </div>
            <div className="Footer__information__item">
              <a href={firstIssueUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.firstIssue}
              </a>
            </div>
            <div className="Footer__information__item">
              <a href={secondIssueUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.secondIssue}
              </a>
            </div>
            <div className="Footer__information__item">
              <a href={extendFirstUrl} target="_blank" rel="noreferrer">
                {t.footer.navigation.extendFirst}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer__copyright">
        <div className="Footer__copyright-content">
          <div className="Footer__copyright-content-rights">
            Multicines © 2022. {t.footer.copyright}
          </div>
          <a
            className="Footer__copyright__company"
            href={COMPANY_URL}
            target="_blank"
            rel="noreferrer"
          >
            {t.footer.develop} <span>TRD</span>
          </a>
        </div>
      </div>
    </Styles>
  );
};

Footer.defaultProps = {
  className: ""
};

export default Footer;
